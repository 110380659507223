import React, { useState, useEffect, useRef } from 'react'
import arrow from '../../../images/dropdown-arrow.svg';
import { countrys } from '../../../consts/data'
import { certificatesTypes, certificatesTypesApostilInCourt } from '../../../consts/data'
import info from '../../../images/info.svg'
import needPay from '../../../images/no-exemption.svg'
import fullExemption from '../../../images/full-exemption.svg'
import { Orderservice } from '../../../services/orderService'
import { useDispatch } from 'react-redux'
import { updateErrorModal } from '../../../store/actions/systemActions'
import remove from '../../../images/remove1.png';
import { Tooltip } from '../../Utils/Tooltip'
import { TooltipTriangle } from '../../Utils/TooltipTriangle'
import { toolTipText } from '../tooltipText'
import { useSelector } from 'react-redux'


export function ApostilleVerification({ data, setData, submitForm, setSubmitForm, errors, isError, type, scrollTo }) {
    const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
    const [isCertificatesDropdownOpen, setIsCertificatesDropdownOpen] = useState(false);
    const [searchCountry, setSearchCountry] = useState(data?.currCountryApostille?.country || '');
    const [searchCertificate, setSearchCertificate] = useState('');
    const [filteredCertificatesTypes, setFilteredCertificatesTypes] = useState(type === 'apostilSubmission' ? certificatesTypes : certificatesTypesApostilInCourt)
    const refCountry = useRef()
    const refCertificate = useRef()
    const activeUser = useSelector((state) => state.userReducer.user);
    const dispatch = useDispatch();
    const isActionPriceless = activeUser?.pricelessActions && activeUser?.pricelessActions.includes('apostilSubmission')

    const apostilTitles = {
        apostilSubmission: {
            mainTitle: "תעודות לאימות אפוסטיל",
            subTitle: "בחרו את המדינה ואת סוג וכמות התעודות שנדרש לאמת באמצעות אפוסטיל משרד החוץ.",
            whichCertificates: "אילו תעודות נדרשות אימות?",
            whichCertificatesSub: "יש לבחור את כמות התעודות לאימות מכל סוג",
            toll: "אין פטור. עליך לשלם אגרה",
            tollSub: [
                "יש לשלם אגרה עבור כל תעודה שנדרשים לאמת ובלבד שהמדינה חתומה על",
                "אמנת האג."
            ],
            cerPlaceHolder: "הזינו ובחרו תעודות"
        },
        apostilInCourt: {
            mainTitle: "אימות אפוסטיל לאישור נוטריוני",
            subTitle: "בחרו את המדינה אליה מיועדים המסמכים, ואת סוג וכמות המסמכים הנוטריונים שנדרשים אימות באמצעות אפוסטיל בית משפט.",
            whichCertificates: "אילו אישורים נוטריונים נדרשים אימות אפוסטיל?",
            whichCertificatesSub: "יש לבחור את כמות המסמכים הנוטריונים לאימות מכל סוג",
            toll: "אין פטור. עליך לשלם אגרה שתתווסף אוטומטית לסיכום ההזמנה",
            tollSub: [
                "יש לשלם אגרה בסך 38₪ עבור כל מסמך לאימות אפוסטיל ובלבד שהמדינה אליה מיועדים המסמכים חתומה על",
                "אמנת האג."
            ],
            cerPlaceHolder: "הזינו ובחרו את סוג המסמכים"

        },
    }


    useEffect(() => {
        const { currCountryApostille, certificates } = data
        if (!currCountryApostille && !certificates) {
            setData({ ...data, currCountryApostille: '', certificates: [] });
        }
    }, [])

    useEffect(() => {
        const onBodyClick = (event) => {
            if (refCountry.current.contains(event.target) || refCertificate.current.contains(event.target)) {
                return;
            }
            setIsCertificatesDropdownOpen(false);
            setIsCountryDropdownOpen(false);
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });

        return () => {
            document.body.removeEventListener("click", onBodyClick, {
                capture: true,
            });
        };
    }, []);

    useEffect(() => {
        const { certificates } = data
        if (certificates && certificates.length > 0) {
            document.getElementById('certificates').focus();
        }
    }, [data?.certificates?.length || data]);

    useEffect(() => {
        if (isCertificatesDropdownOpen) {
            document.getElementById('certificates').focus();
        }
    }, [isCertificatesDropdownOpen]);

    useEffect(() => {
        if (submitForm && errors.currCountryApostille) {
            setSubmitForm(false);
            dispatch(
                updateErrorModal({
                    title: 'שגיאה',
                    errorText: 'חובה לבחור מדינה',
                    submitText: 'אישור',
                    isCancel: false
                })
            );
        } else if (submitForm && errors.certificates) {
            setSubmitForm(false);
            dispatch(
                updateErrorModal({
                    title: 'שגיאה',
                    errorText: 'חובה לבחור תעודה',
                    submitText: 'אישור',
                    isCancel: false
                })
            );
        }
    }, [data.certificates, submitForm, errors, data.currCountryApostille]);

    useEffect(() => {
        if (data.currCountryApostille?.isAmana && type === 'apostilSubmission') {
            setData({
                ...data,
                certificates: data.certificates.filter(cer => cer.name !== 'תרגום נוטריוני')
            })
            setFilteredCertificatesTypes(certificatesTypes.filter(cer => cer.name !== 'תרגום נוטריוני'));
        } else {
            setFilteredCertificatesTypes(type === 'apostilSubmission' ? certificatesTypes : certificatesTypesApostilInCourt)
        }
    }, [data.currCountryApostille])

    const onChange = (field, value) => {
        if ('certificates' === field) {
            data.certificates = !data.certificates ? [] : data.certificates;
            setData({
                ...data,
                certificates: [...data.certificates,
                { ...value, counter: 1, id: Orderservice.makeId(), isVerification: false, isToll: type === 'apostilInCourt' ? true : false, tollPrice: type === 'apostilInCourt' ? 40 : 0 }]
            });
        }
        else {
            setData({ ...data, [field]: value });
        }
    }

    const handleChangeCounter = (e, certificate, operation) => {
        if (operation === 'add') {
            certificate.counter += 1
        } else if (operation === 'reduce') {
            if (certificate.counter - 1 <= 0) {
                return
            }
            certificate.counter -= 1
        } else {
            certificate.counter = (+e.target.value === 0) ? 1 : +e.target.value
        }
        const toll = 40
        certificate.tollPrice = toll * certificate.counter
        setData({
            ...data,
            certificates: data.certificates.map(currCertificate => currCertificate.id === certificate.id ? certificate : currCertificate)
        })
    }

    const handleCheckboxs = (e, certificate) => {
        certificate[e.target.name] = e.target.checked
        if (e.target.name === 'isToll' && e.target.checked === false) {
            certificate.tollPrice = 0
        }
        else if (e.target.name === 'isToll' && e.target.checked === true) {
            const toll = 40
            certificate.tollPrice = toll * certificate.counter
        }

        setData({
            ...data,
            certificates: data.certificates.map(currCertificate => currCertificate.id === certificate.id ? certificate : currCertificate)
        })
    }

    const onRemoveCertificate = (certificateId) => {
        const newCertificates = data.certificates.filter(currCertificate => currCertificate.id !== certificateId)
        setData({ ...data, certificates: newCertificates });
    }

    const getToolTipText = (a) => {
        return toolTipText[a]
    }

    const openUrl = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div className='main-form apostil-container' >
            <div className='modal-content-container'>

                {/* <h2 className="large-title">תעודות לאימות אפוסטיל</h2> */}
                {/* <p className='subtitle'>בחרו את המדינה ואת סוג וכמות המסמכים שנדרשים אימות באמצעות אפוסטיל משרד החוץ.</p> */}
                <h2 className="large-title">{apostilTitles[type]?.mainTitle}</h2>
                <p className='subtitle'>{apostilTitles[type]?.subTitle}</p>

                <div className='section-choose'>
                    <h3>לאיזו מדינה מיועדות התעודות?</h3>
                    <p>{apostilTitles[type]?.tollSub[0]} <span className='url-info' onClick={() => { openUrl('https://he.wikipedia.org/wiki/%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C#%22%D7%90%D7%9E%D7%A0%D7%AA_%D7%94%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C%22') }}> {apostilTitles[type]?.tollSub[1]}</span></p>
                    {/* <p>יש לשלם אגרה עבור כל תעודה שנדרשים לאמת ובלבד שהמדינה חתומה על  <span className='url-info' onClick={() => { openUrl('https://he.wikipedia.org/wiki/%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C#%22%D7%90%D7%9E%D7%A0%D7%AA_%D7%94%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C%22') }}>אמנת האג</span></p> */}
                    <div className="dropdown-icon-align" >
                        <div
                            ref={refCountry}
                            className={
                                isCountryDropdownOpen ? 'dropdown-container open' : 'dropdown-container'
                            }
                        >
                            <div className='dropdown-input'>
                                <input
                                    type="text"
                                    placeholder='הזינו ובחרו מדינה'
                                    onChange={(event) => {
                                        setSearchCountry(
                                            event.target.value
                                        );
                                        setIsCountryDropdownOpen(true);
                                    }}
                                    value={searchCountry}
                                    onClick={() => { setIsCountryDropdownOpen(true); }}
                                />
                                <button
                                    type='button'
                                    onClick={() => {
                                        setIsCountryDropdownOpen(!isCountryDropdownOpen);
                                    }}
                                >
                                    <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                                </button>
                            </div>

                            <div className='dropdown-list'>
                                {countrys.filter((option) => option.country.includes(searchCountry)).map((country, idx) => {
                                    return (
                                        <div className='dropdown-option' key={idx}>
                                            <p
                                                onClick={() => {
                                                    onChange('currCountryApostille', country);
                                                    setSearchCountry(country.country)
                                                    setIsCountryDropdownOpen(!isCountryDropdownOpen);
                                                }}>
                                                {country.country}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {data?.currCountryApostille && <div className="country-msg">
                            <img className="check-icon" src={data.currCountryApostille && data.currCountryApostille.isAmana ? needPay : fullExemption} alt="icon" />
                            <p>
                                {data.currCountryApostille && !data.currCountryApostille.isAmana ? 'פטור מלא מתשלום אגרה!' : apostilTitles[type].toll}
                            </p>
                        </div>}
                    </div>

                </div>

                <div className='section-choose'>
                    {/* <h3>אילו תעודות נדרשות אימות?</h3> */}
                    <h3>{apostilTitles[type].whichCertificates}</h3>
                    <p>{apostilTitles[type].whichCertificatesSub}</p>
                    {type !== 'apostilInCourt' &&
                        <p className='bold'>*שימו לב! ניתן לאמת תעודות מקוריות ישראליות בלבד*</p>
                    }
                    <div
                        ref={refCertificate}
                        className={
                            isCertificatesDropdownOpen ? 'dropdown-container open' : 'dropdown-container'
                        }
                    >

                        <div className='dropdown-input'>
                            <input
                                type="text"
                                placeholder={apostilTitles[type]?.cerPlaceHolder}
                                onChange={(event) => {
                                    setSearchCertificate(
                                        event.target.value
                                    );
                                    setIsCertificatesDropdownOpen(true);
                                }}
                                value={searchCertificate}
                                onClick={() => { setIsCertificatesDropdownOpen(true); scrollTo('down'); }}
                            />
                            <button
                                type='button'
                                onClick={() => {
                                    setIsCertificatesDropdownOpen(!isCertificatesDropdownOpen);
                                    setSearchCertificate('');
                                }}
                            >
                                <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                            </button>
                        </div>

                        <div className='dropdown-list'>
                            {filteredCertificatesTypes.filter((option) => option.name.includes(searchCertificate)).map((certificate, idx) => {
                                return (
                                    <div className='dropdown-option'
                                        key={certificate.name + idx}
                                        onClick={() => {
                                            onChange('certificates', certificate);
                                            setIsCertificatesDropdownOpen(!isCertificatesDropdownOpen);
                                            setSearchCertificate('')
                                        }
                                        }>
                                        <p>
                                            {certificate.name}
                                        </p>
                                        <div>
                                            <button className='btn-add' type='button'>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div tabIndex="0" id='certificates' className="certificate-container">
                    {(data?.certificates && data.certificates.length > 0) ?
                        data.certificates.map((certificate, idx) => {
                            return (
                                <div key={idx + certificate.name} className={`certificate ${type === 'apostilInCourt' ? 'no-height' : ''}`}>
                                    <div className="details">
                                        <p className="name">{certificate.name}</p>
                                        <div className="btn-container">
                                            <img
                                                src={remove}
                                                alt="remove"
                                                onClick={() => onRemoveCertificate(certificate.id)} />
                                        </div>
                                    </div>


                                    <div className="status number">
                                        <p>{type === 'apostilInCourt' ? 'כמה מסמכים לאמת?' : 'כמה תעודות לאמת?'}</p>
                                        <div className="number-input">
                                            <button type='button' className="minus" onClick={(e) => handleChangeCounter(e, certificate, 'add')}>+</button>
                                            <input
                                                type="number"
                                                min={0}
                                                className="quantity"
                                                value={certificate.counter}
                                                onChange={(e) => handleChangeCounter(e, certificate)} />
                                            <button type='button' className="plus" onClick={(e) => handleChangeCounter(e, certificate, 'reduce')}>-</button>
                                        </div>

                                    </div>
                                    {data.currCountryApostille && data.currCountryApostille.isAmana && type === 'apostilSubmission' && <div className='status with-info'>
                                        <label className="checkbox-container">
                                            <input type="checkbox" name='isToll' checked={certificate.isToll} value={true} onChange={(e) => handleCheckboxs(e, certificate)} />
                                            <p className="checkbox-text">שלמו עבורי אגרה   {`בתוספת ${certificate.counter * 40}₪ `}</p>
                                            <span className="checkmark"></span>
                                        </label>


                                        {/* <div >
                                        <input type='checkbox' className='checkbox' name='isToll' value={true} onChange={(e) => handleCheckboxs(e, certificate)} />
                                        <p>שלמו עבורי את האגרה בתוספת של {certificate.counter * 40} ₪</p>
                                    </div> */}
                                        <TooltipTriangle text={getToolTipText('toll')}>
                                            <img src={info} alt="info-icon" className="info-icon" />
                                        </TooltipTriangle>
                                    </div>}
                                    {certificate.isCertificate && <div className='status with-info'>
                                        <label className="checkbox-container">
                                            <input type="checkbox" name='isVerification' value={true} checked={certificate.isVerification} onChange={(e) => handleCheckboxs(e, certificate)} />
                                            <span className="checkmark"></span>
                                            <p className="checkbox-text"> בצעו עבורי אימות מקדים {certificate?.certificateText || ''} {isActionPriceless ?
                                                '' : (`בתוספת ${certificate?.certificateCost || 100} ₪ `)}<br/>{['באגף הבחינות של משרד החינוך', 'במשרד החינוך'].includes(certificate?.certificateText) ? <strong>*האימות המקדים לוקח לפחות 14 ימי עסקים, בנוסף לזמן השירות הנבחר*</strong> : ''}</p>
                                        </label>
                                        {certificate.name !== 'תעודה אחרת' && <TooltipTriangle text={certificate.varificationtoolTip}>

                                            <img src={info} alt="info-icon" className="info-icon" />
                                        </TooltipTriangle>}

                                        {/* <input type='checkbox' name='isVerification' value={true} onChange={(e) => handleCheckboxs(e, certificate)} />
                                    <p>  בצעו עבורי אימות מקדים תוספת {100} ₪  </p> */}
                                    </div>}
                                    {certificate.link && <div className="example-container" style={{ marginTop: "auto" }}>
                                        {/* {certificate.link && <div className="example-container"> */}
                                        {/* <p>לצפייה בתעודה לדוגמא</p> */}
                                        {certificate.name !== 'תעודה אחרת' && <a href={certificate.link} target="_blank" rel="noreferrer">
                                            <div className={`example-icon ${data.type === 'apostilSubmission' ? 'apostilSubmission-btn' : 'apostilInCourt-btn'}`}>לחצו כאן לצפייה בתעודה לדוגמא</div>
                                        </a>}

                                    </div>}
                                </div>
                            )
                        })
                        :
                        <div style={{ "minHeight": isCertificatesDropdownOpen ? '300px' : '0' }} ></div>
                    }
                </div>
            </div>
        </div >
    )
}

export default ApostilleVerification
