// import for DB
import db, { trace, perf} from '../../../integrations/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
// import for store
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import emptyBox from '../../../images/empty_box.svg'
import cart from "../../../images/cart-footer-black.svg";
import secure from "../../../images/secure.svg"
import { ReactComponent as Edit } from '../../../images/edit-grey.svg'
import { ReactComponent as Remove } from '../../../images/remove-grey.svg'
import { updateData } from '../../../store/actions/dataActions';
import { removeOrder } from '../../../store/actions/orderActions';
import { updateSystemData, updateErrorModal, setMobileState } from '../../../store/actions/systemActions';
import { setOrders } from '../../../store/actions/orderActions'
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import {
  setFiles,
} from '../../../store/actions/fileActions';
import { useNavigate } from 'react-router-dom';
import { utilService } from '../../../services/utilService';

export function Sidebar() {
  const t = trace(perf, "Sidebar");
  t.start();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orders = useSelector((state) => state.orderReducer.Orders);
  const [totalCost, setTotalCost] = useState({
    total: 0,
    totalVat: 0,
    totalToll: 0,
  });
  const [orderToDelete, setOrderToDelete] = useState(null);
  const isPopUpModal = useSelector((state) => state.systemReducer.isPopUpModal);
  const activeUser = useSelector((state) => state.userReducer.user);
  const recipientsData = useSelector((state) => state.recipientReducer.Recipients);
  const systemData = useSelector((state) => state.systemReducer.systemData)
  const mobile = useSelector((state) => state.mobileReducer.mobileState);
  const orderActions = orders.map(order => order.action.type)
  const isAllActionsArePriceless = orderActions?.every(action => activeUser?.pricelessActions?.includes(action));
  // Overwrites local storage whenever state orders changes
  useEffect(() => {
    localStorage.setItem('lastRecipients', JSON.stringify(recipientsData));
  }, [recipientsData, systemData.isCheckout]);

  useEffect(() => {
    if (activeUser) {
      const unsub = onSnapshot(doc(db, "activeCarts", activeUser.uid), (doc) => {
        dispatch(setOrders(doc.data()?.orders || []))
      });
      return () => {
        unsub();
      }
    }
  }, [activeUser]);

  // Calculating the total price
  useEffect(() => {
    const cartTotalPrice = orders.reduce((acc, ord) => {
      acc += ord.prices.data.total === -1 ? 0 : (ord.prices.data.totalPriceBeforeDiscount || ord.prices.data.total);
      return acc;
    }, 0);

    const cartTotalDiscount = orders.reduce((acc, ord) => {
      acc += (ord.prices.data.totalPriceBeforeDiscount) ? (ord.prices.data.totalPriceBeforeDiscount - ord.prices.data.total) : 0;
      return acc
    }, 0)


    const cartVatPrice = orders.reduce((acc, ord) => {
      acc += ord?.prices?.data?.vat || 0
      return acc;
    }, 0);

    const cartTollPrice = orders.reduce((acc, ord) => {
      if (ord.prices.data.total !== -1 || activeUser?.pricelessActions?.includes(ord.action.type)) {
        if (ord.type === 'apostilSubmission') {
          acc += utilService.calcTollByOrderType(ord)
        } else if (ord.type === 'apostilInCourt') {
          acc += utilService.calcTollByOrderType(ord)
        } else if (ord.type === 'courtSubmission') {
          acc += utilService.calcTollByOrderType(ord)
        } else if (ord.type === 'personLocate') {
          acc += utilService.calcTollByOrderType(ord)
        } else if (ord.type === 'landRegistration') {
          acc += utilService.calcTollByOrderType(ord)
        }
        else {
          acc += ord.prices.data?.totalToll || 0
        }
      }
      return acc;
    }, 0);

    setTotalCost({
      total: cartTotalPrice, totalVat: cartVatPrice, totalToll: cartTollPrice, totalDiscount: cartTotalDiscount
    });

  }, [orders]);

  useEffect(() => {
    const { isDeleteOrder, status } = isPopUpModal;

    async function clearFiles() {
      const storage = await getStorage();
      const storageRef = ref(storage, `${activeUser.uid}/${orderToDelete}`);
      const filesList = await listAll(storageRef)
      filesList.items.map(async (file) => {
        const storage = getStorage();
        const fileRef = ref(storage, file._location.path_);
        await deleteObject(fileRef)
      })
      dispatch(setFiles([]))
    }

    if (orderToDelete && isDeleteOrder && status === 'submit') {
      dispatch(removeOrder(activeUser, orderToDelete))
      setOrderToDelete(null);
      clearFiles();
    }
  }, [isPopUpModal.status, isPopUpModal.isDeleteOrder]);

  const calcTollByOrderType = (ord) => {
    let toll = 0
    if (ord.type === 'apostilSubmission' || ord.type === 'apostilInCourt') {
      toll = ord.certificates.reduce((acc, cer) => {
        acc += cer.isToll ? (cer.counter * 40) : 0
        return acc;
      }, 0);
      return toll
    } else if (ord.type === 'courtSubmission') {
      return (ord.toll || 0)
    } else if (ord.type === 'personLocate') {
      toll = ord.recipients.reduce((acc, recipient) => {
        acc += 20
        return acc;
      }, 0);
      return toll
    } else if (ord.type === 'landRegistration') {
      return (ord?.depositFiles?.toll || 0)
    } else if ((ord.type === 'companiesRegistration')
      || (ord.type === 'inheritanceRegistration')
      || (ord.type === 'partnershipsRegistration')
      || (ord.type === 'associationsAndCompanies')
      || (ord.type === 'smallClaim')) {
      return (ord?.prices?.data?.totalToll || 0)
    }
  }

  const onEditOrder = async (order) => {
    dispatch(updateData({ ...order }));
    dispatch(updateSystemData({
      ...systemData,
      isFormModalOpen: true,
      action: order.action,
      isEditMode: true
    }));
    dispatch(setMobileState({
      ...mobile,
      isModalToolbarOpen: false
    }))
    navigate(!systemData.isCheckout ? `/forms/${order.action.formId}` : `/checkout/${order.action.formId}`);
  };

  const onRemoveOrder = (orderId) => {
    dispatch(updateErrorModal({
      title: 'אזהרה',
      errorText: 'במידה ותלחץ על אישור ההזמנה תמחק',
      submitText: 'אישור',
      status: 'delete_order',
      isCancel: true
    }));
    setOrderToDelete(orderId);
  };

  const onSubmitOrder = () => {
    dispatch(updateSystemData({
      ...systemData,
      isCheckout: true,
    }));

    dispatch(setMobileState({
      ...mobile,
      isModalToolbarOpen: false
    }))
    navigate('/checkout');
  };
  //!order?.prices?.data?.isBid &&
  const returnValue = (
    <div className="sidebar-content">
      <div className="inner-container">
        {orders.length > 0 ? (
          <div className="sidebar-title">
            <div className="cart-icon">
              <img src={cart} alt="cart icon" />
              <span className="badge">{orders?.length || 0}</span>
            </div>
            <h3>ההזמנה שלך</h3>
          </div>
        ) : null}

        <div className="orders-preview-container">
          {orders && orders.length > 0 ? (
            orders.map((order, index) => {
              return (
                <div key={order.nid} id={order.id} className="order-preview">
                  <div className="header">
                    <div>
                      <img className="icon" alt='icon' src={order.action.formImage} />
                      <h4 className="order-title">{order.action.formTitle}</h4>
                    </div>
                    <div className="btn-container">

                      <Remove onClick={() => { onRemoveOrder(order._id) }} />
                      <Edit onClick={() => { onEditOrder(order) }} />
                    </div>
                  </div>
                  {<>
                    <div className="order-summary" style={{
                      borderBottom: order?.prices?.data?.isBid || (activeUser?.pricelessActions?.includes(order.action.type)) ?
                        'none' :
                        '1px solid #e6e9ef'
                    }}>
                      {order?.prices?.data?.isBid
                        ?
                        <></>
                        :
                        <>
                          {!(activeUser?.pricelessActions?.includes(order.action.type)) && <div className="summary-item">
                            <p className="item-title">סכום ביניים</p>
                            <p>{(order.prices.data?.totalPriceBeforeDiscount && order.prices.data?.totalPriceBeforeDiscount.toFixed(2)) || (order.prices.data.total).toFixed(2)} ₪</p>
                          </div>}
                          {(activeUser.fullName && order.prices.data?.totalPriceBeforeDiscount) && <div className='summary-item'>
                            <p>הנחה {activeUser.discountPercentage}%</p>
                            <p>{(order.prices.data?.totalPriceBeforeDiscount * (activeUser.discountPercentage / 100)).toFixed(2)}- ₪</p>
                          </div>}
                          {!(activeUser?.pricelessActions?.includes(order.action.type)) && <div className="summary-item">
                            <p >מע"מ </p>
                            <p>{order?.prices?.data?.vat?.toFixed(2) || 0.00} ₪</p>
                          </div>}
                        </>
                      }
                      {calcTollByOrderType(order) > 0 && <div className="summary-item">
                        <p >אגרה </p>
                        <p>{calcTollByOrderType(order) || 0.00} ₪</p>
                      </div>}
                    </div>
                    {order?.prices?.data?.isBid
                      ?
                      <></>
                      :
                      <>
                        {!(activeUser?.pricelessActions?.includes(order.action.type)) && <div className="summary-total">
                          <p className="item-title">סה"כ לתשלום</p>
                          <p>{(order.prices.data.total + order.prices.data.vat + order.prices.data.totalToll)?.toFixed(2) || 0.00} ₪</p>
                        </div>}
                      </>
                    }
                  </>}
                </div>
              )
            })

          ) : (
            <div className="empty-cart">
              <img src={emptyBox} alt="empty-box-icon" />
              <h3>אופס.. עוד לא הוספת כלום</h3>
              <p>יש לבחור שירות ולהוסיף להזמנה שלך</p>
            </div>
          )}
        </div>
        {(orders.length > 0) && (
          <div className="cart-summary">
            {<>
              <div className="total">
                {!isAllActionsArePriceless && <div className="total-item">
                  <p className="title">סיכום ביניים</p>
                  <p>{(totalCost.total).toFixed(2)} ₪</p>
                </div>}
                {!isAllActionsArePriceless && totalCost.totalDiscount > 0 && <div className="total-item">
                  <p className="title">הנחה</p>
                  <p>{(totalCost.totalDiscount).toFixed(2)}- ₪</p>
                </div>}
                {!isAllActionsArePriceless && <div className="total-item">
                  <p className="title">מע"מ</p>
                  <p>{totalCost.totalVat.toFixed(2)} ₪</p>
                </div>}
                <div className="total-item agra">
                  <p className="title">אגרות</p>
                  <p>{totalCost.totalToll.toFixed(2)} ₪</p>
                </div>
              </div>
              <div className="total-final">
                <p>סה"כ לתשלום</p>
                <p>{(totalCost.total + totalCost.totalVat + totalCost.totalToll - totalCost.totalDiscount).toFixed(2)} ₪</p>
              </div>
            </>}
            {!isAllActionsArePriceless && <div className="security-note">
              <div>
                <img src={secure} alt="secure" />
                <p>המידע שלכם שמור בבטחה</p>
              </div>
              <p>רכישה מאובטחת בתקן PCI DSS</p>
            </div>}
            {(!window?.location?.pathname.includes('checkout') || mobile.isModalToolbarOpen) && <button className='main-btn' onClick={onSubmitOrder}>השלמת הזמנה</button>}
          </div>)
        }

      </div>
    </div >

  );
  t.stop();
  return returnValue
}



export default Sidebar;
